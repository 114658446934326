import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import { Button, Col, Row } from "reactstrap";
import FAQEntry from "../components/FAQEntry";

const FAQ = () => (
	<Layout>
		<SEO title="FAQ"/>
		<ContentContainer title="FAQ" subtitle="Die häufigst gestellten Fragen zu tinify.me" half>
			<Row>
				<Col sm={12} lg={8}>
					<FAQEntry question="Was macht tinify.me?"
							answer="tinify.me komprimiert Bilder und GIF‘s um die Ladezeit deiner Webseite zu reduzieren. Der Kompressor ist ultra-schnell und kann die Speichergröße stark verkleinern. Ein absolutes Must-Have für jeden, den Suchmaschinenoptimierung interessiert." />
					<FAQEntry question="Warum sollte ich tinify.me nutzen?"
							answer="Das fragst du wirklich noch? tinify.me ist ultra-schnell, einfach zu nutzen und kann eine Komprimierung fast ohne Qualitätsunterschiede vornehmen. Du brauchst noch mehr Vorteile? Kein Problem: tinify.me ist vollkommen kostenlos und frei nutzbar. Fairer können wir nur wirklich nicht sein!" />
					<FAQEntry question="Was passiert mit meinen Bildern nach der Komprimierung?"
							answer="Ehrlich gesagt interessiert es uns nicht, was du hier für Bilder komprimierst. Wir speichern keine Bilder auf unseren Server. Nach einer Komprimierung speichern wir ein paar Statistiken. Denn Statistiken sind immer cool. Dazu gehört: Wann wurde ein Bild komprimiert? Wie groß war das Bild? Wie groß ist es danach? Und was für ein Typ Bild war es (PNG, JPG, GIF)? Diese Statistiken kannst du in deinem Dashboard einsehen, wenn du dir einen Account erstellst." />
					<FAQEntry question="Warum haben wir tinify.me entwickelt?"
							answer="Ganz einfach, weil wir es können. Nein, Spaß beiseite! Wir stellen uns immer wieder neuen Herausforderungen und sind immer auf der Suche nach Neuem. tinify.me ist ein internes Projekt unserer Werbeagentur, mit denen unsere Entwickler sich mal richtig ausleben durften. Hoffentlich gefällt dir unsere Arbeit." />
					<FAQEntry question="Woher weiß ich, welche Bilder optimiert werden müssen?"
							answer="Es gibt viele verschieden Tools, mit denen du überprüfen kannst, wie viel deine Bilder optimiert werden können. Probiere einfach eins der folgenden Tools aus: GTMetrix, Google Pagespeed Insights oder WebPageTest" />
					<FAQEntry question="Kann tinify.me auch GIF’s kleinrechnen?"
							answer="Ja! Auch GIF’s können von tinify.me kleingerechnet werden. Nutze dazu einfach die erweiterten Einstellungen und wähle deine GIF Optimierungs-Level und dein GIF Loss Level." />
					<FAQEntry question="Kann tinify.me dir bei einer SEO-Optimierung helfen?"
							answer="Definitiv! tinify.me komprimiert, fast ohne Qualitätsunterschiede die Bilder für deine Webseite. Dadurch kann der Speicherplatz reduziert und dadurch die Ladezeit verkürzt werden. Das kann wiederum positive Auswirkungen auf deine SEO-Rankings haben." />
				</Col>
				<Col sm={12} lg={4}>
					<div className="border rounded border-grey p-3">
						<p className="font-weight-bold">Findest du deine Frage hier nicht?</p>
						<p className="color-subtext">Schreibe uns eine Nachricht und wir werden gerne versuchen dir zu helfen.</p>
						<Button color="primary" className="px-5" href="/contact">Kontakt</Button>
					</div>
				</Col>
			</Row>
		</ContentContainer>
	</Layout>
);

export default FAQ;
