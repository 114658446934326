import React, { useState } from "react";
import IconOpen from "../images/icon-add-circle-outline.svg";
import IconClose from "../images/icon-remove-circle.svg";

const FAQEntry = ({ question, answer }) => {

	const [open, setOpen] = useState(false);

	return (
		<div className={ `px-3 pt-4 transition-bg mb-3 ${ open ? "faq-background-tint rounded" : "border-bottom border-grey" }` }>
			<div className="d-flex justify-content-between align-items-center cursor-pointer pb-3"
				 onClick={ () => setOpen(!open) }>
				<p className="font-weight-bold m-0">{ question }</p>
				{	open
					? <img src={IconClose} className="faq-toggle" alt="close-faq"/>
					: <img src={IconOpen} className="faq-toggle" alt="open-faq" />
				}
			</div>
			{ open &&
			<p className="pb-3 pr-5 color-subtext">{ answer }</p>
			}
		</div>
	);
};

export default FAQEntry;
